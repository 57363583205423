import React, { Component } from 'react'
import {
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap'
import axios from 'axios'
import { HOSTNAME } from '../config'
import { CopyToClipboard } from 'react-copy-to-clipboard'

class AssignModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      shareUrl: null,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.ticketId && prevProps.ticketId !== this.props.ticketId) {
      this.getShareUrl()
    }
  }

  getShareUrl() {
    axios
      .post(HOSTNAME + 'getShortUrl', {
        url: `${window.location.origin}/signup?ticketId=${this.props.ticketId}`,
      })
      .then(res => {
        this.setState({ shareUrl: res.data.url })
      })
  }

  render() {
    return (
      <div>
        <Modal isOpen={this.props.show} className={this.props.className}>
          <ModalHeader toggle={this.props.handleClose}>
            Assign Your Ticket
          </ModalHeader>

          <ModalFooter>
            <div className="assign-modal__footer">
              {this.props.canSelfClaim ? (
                <div className="assign-modal__button--claim">
                  <h5>Claim Ticket</h5>
                  <p>
                    Put your name on this ticket. You’ll be ready at the door.
                  </p>
                  <button
                    className="btn ticket_btn"
                    onClick={() =>
                      this.props.handleSelfClaim(this.props.ticketId)
                    }
                  >
                    Claim Ticket
                  </button>
                </div>
              ) : null}

              <div className="assign-modal__copy-text">
                <h5>Share Ticket</h5>
                <p>
                  Share the link below to assign the ticket to someone else.
                </p>
                <div className="assign-modal__copy">
                  <Input
                    disabled={true}
                    type="text"
                    value={this.state.shareUrl}
                  />

                  <CopyToClipboard text={this.state.shareUrl}>
                    <div className="assign-modal__button--assign">
                      <button
                        className="btn ticket_btn"
                        onClick={this.props.handleClose}
                      >
                        Copy
                      </button>
                    </div>
                  </CopyToClipboard>
                </div>
              </div>
            </div>
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}

export default AssignModal
