import React, { Component } from 'react'
import { Link, navigate } from 'gatsby'
import axios from 'axios'
import NoTicket from './../../assets/images/no-ticket.svg'
import { Button } from 'reactstrap'
import Cookies from 'universal-cookie'
import { HOSTNAME } from '../config'
import AssignModal from '../assignModal/component'
import QRCode from 'qrcode.react'

const cookies = new Cookies()

let initialState = {
  isLoading: true,
  tickets: [],
  user: cookies.get('user'),
  userUid: null,
  showModal: false,
  selectedTicketId: null,
}

class Tickets extends Component {
  constructor(props) {
    super(props)
    this.state = initialState
    this.handleModalClose = this.handleModalClose.bind(this)
    this.handleSelfClaim = this.handleSelfClaim.bind(this)
  }

  componentDidMount() {
    if (!this.state.user) {
      navigate('/login')
      return
    }
    const user = this.state.user
    user.phone = user.phoneNumber
    
    axios
      .post(process.env.GATSBY_RAILS_API_URL + '/users/uid', user)
      .then(uidRes => {
        this.setState({ userUid: uidRes.data.uid })

        axios
          .get(
            process.env.GATSBY_RAILS_API_URL +
              `/users/${uidRes.data.uid}/tickets`
          )
          .then(res => {
            this.setState({ tickets: res.data, isLoading: false })
          })
      })
      .catch(err => {
        this.setState({ isLoading: false })
      })
  }

  handleAssignClick(ticketId) {
    this.setState({ showModal: true, selectedTicketId: ticketId })
  }

  handleModalClose() {
    this.setState({ showModal: false })
  }

  handleSelfClaim(ticketId) {
    axios
      .put(
        process.env.GATSBY_RAILS_API_URL +
          `/users/${this.state.userUid}/tickets/${ticketId}`,
        {
          ticket: {
            claimed: true,
          },
        }
      )
      .then(res => {
        const foundTicket = this.state.tickets.find(
          ticket => ticket.id === ticketId
        )
        foundTicket.claimed = true
      })

    const newTickets = this.state.tickets.map(ticket => {
      if (ticketId !== ticket.id) {
        return ticket
      }
      const newTicket = Object.assign({}, ticket)
      newTicket.claimed = true

      return newTicket
    })

    this.setState({ tickets: newTickets, showModal: false })
  }

  getDate(name) {
    let eventDate = '' 
    name = name ? (name).toLowerCase() : false
      if (name && name.indexOf('austin') !== -1) {
        eventDate = 'Oct 19-20'
      } else if (name && name.indexOf('brooklyn') !== -1) {
        eventDate = 'Nov 16-17'
      } else if (name && name.indexOf('washington') !== -1) {
        eventDate = 'Dec 14-15'
      } else if (name && name.indexOf('denver') !== -1) {
        eventDate = 'Sep 7-8'
      } else if (name && name.indexOf('boston') !== -1) {
        eventDate = 'Sep 28-29'
      }
    return eventDate
  }

  render() {
    const {
      isLoading,
      tickets,
      user,
      showModal,
      selectedTicketId,
      userUid,
    } = this.state
    const claimedTicket = tickets.find(
      ticket => ticket.claimed && ticket.user_id === userUid
    )
    return (
      <div>
        {isLoading ? (
          <div className="loader__section">
            <div className="loading" />
          </div>
        ) : (
          <div id="wrappper" className="tickets__page page">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="ticket-container">
                    {claimedTicket ? (
                      <div className="ticket">
                        <div className="ticket-notch" />
                        <div className="ticket-notch-white" />
                        <div className="ticket__header">
                          <div className="ticket__header--title">
                            <h2>{claimedTicket.name}</h2>
                          </div>
                          <div className="ticket__header--date">
                            <h4>{this.getDate(claimedTicket.name)}</h4>
                          </div>
                        </div>
                        <div className="ticket__footer">
                          <div className="ticket__footer--assignment">
                            <h4>{claimedTicket.ticket_type}</h4>
                          </div>
                          <div className="ticket__footer--owner">
                            <h2>
                              {user.firstName} {user.lastName}
                            </h2>
                          </div>
                        </div>
                        {!claimedTicket.scanned ? (
                          <div className="ticket__qr-code">
                            <QRCode
                              value={`${
                                process.env.GATSBY_SCAN_URL
                              }/users/${userUid}/tickets/${
                                claimedTicket.id
                              }/scan`}
                              size={140}
                            />
                          </div>
                        ) : null}
                      </div>
                    ) : null}

                    {tickets.length === 0 ? (
                      <div className="tickets__blank">
                        <h2 className="tickets__title">No Tickets</h2>
                        <div className="tickets__blank--img">
                          <img src={NoTicket} />
                        </div>
                        <a
                          href={`https://shopaf.co/pages/attend`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <button className="btn ticket_btn purchase">
                            Purchase Tickets
                          </button>
                        </a>
                      </div>
                    ) : null}

                    {tickets.map(ticket => {
                      if (ticket.claimed && ticket.user_id === userUid) {
                        return null
                      }

                      return (
                        <div
                          className={
                            ticket.ticket_type === 'GA'
                              ? 'ticket--unassigned'
                              : 'ticket--unassigned-vip'
                          }
                          key={ticket.id}
                        >
                          <div className="ticket-notch" />
                          <div className="ticket-notch-white" />
                          <div className="ticket--unassigned__header">
                            <div className="ticket--unassigned__header--title">
                              <h2>{ticket.name}</h2>
                            </div>
                            <div className="ticket--unassigned__header--date">
                            <h4>{this.getDate(ticket.name)}</h4>
                            </div>
                          </div>
                          <div className="ticket--unassigned__footer">
                            <div className="ticket--unassigned__footer--assignment">
                              <h4>{ticket.ticket_type}</h4>
                            </div>
                            <div className="ticket--unassigned__footer--assign-ticket">
                              <h2>This ticket must be assigned</h2>
                            </div>

                            <div className="ticket__cta">
                              <button
                                className="btn ticket_btn"
                                onClick={() =>
                                  this.handleAssignClick(ticket.id)
                                }
                              >
                                ASSIGN
                              </button>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>

            <AssignModal
              show={showModal}
              handleClose={this.handleModalClose}
              canSelfClaim={!claimedTicket}
              handleSelfClaim={this.handleSelfClaim}
              ticketId={selectedTicketId}
            />
          </div>
        )}
      </div>
    )
  }
}

export default Tickets
